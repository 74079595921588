export const bannerData = [
    { id: 10, url: 'https://www.newhouserealty.co.in/img/residential/residential1.jpeg' },
    { id: 11, url: 'https://www.newhouserealty.co.in/img/residential/residential2.jpeg' },
    { id: 12, url: 'https://www.newhouserealty.co.in/img/residential/residential4.jpeg' },
    { id: 13, url: 'https://www.newhouserealty.co.in/img/residential/residential5.jpeg' },
    
    {id: 14, url: 'https://www.newhouserealty.co.in/img/commercial/commercial4.jpeg'},
    {id: 15, url: 'https://www.newhouserealty.co.in/img/commercial/commercial3.jpeg'},
    // {id: 16, url: ' https://www.newhouserealty.co.in/img/residential/commercial.jpeg'},
    {id: 17, url: ' https://www.newhouserealty.co.in/img/farmhouse/farmhouse1.jpeg'},
    {id: 18, url: ' https://www.newhouserealty.co.in/img/farmhouse/farmhouse2.jpg'}
];